import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MoreErrorsComponent } from './more-errors.component';
import { NgIf } from '@angular/common';
import { MatButton } from '@angular/material/button';

interface ErrorSnackBarData {
  message: string;
  numberOfOtherErrors?: number;
  otherErrors?: string;
}

@Component({
    selector: 'app-error-snack-bar',
    template: `
    <div>
      {{ data.message }}
      <button mat-button (click)="closeSnackbar()" style="float: right" color="accent">
        Close
      </button>
      <button *ngIf="data.numberOfOtherErrors && data.numberOfOtherErrors > 0" mat-button (click)="showMore()" style="float: right">
        Show {{ data.numberOfOtherErrors }} Others
      </button>
    </div>
  `,
    styleUrls: ['error-snack-bar.component.scss'],
    standalone: true,
    imports: [MatButton, NgIf],
})
export class ErrorSnackbarComponent {
  constructor(
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    @Inject(MAT_SNACK_BAR_DATA) public data: ErrorSnackBarData,
  ) {}

  showMore() {
    this.dialog.open(MoreErrorsComponent, {
      data: JSON.parse(this.data.otherErrors),
    });
  }

  closeSnackbar() {
    this.snackbar.dismiss();
  }
}
