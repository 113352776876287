import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { setFilterFormExpanded } from '@main/invoices/store/invoice-filter.actions';
import { selectCustomer } from '@main/jobs/store/job-filter.actions';

export const featureKey = 'invoice-filter';

export interface State {
  filterFormExpanded: boolean;
  selectedCustomerId: number;
}

export const initialState: State = {
  filterFormExpanded: false,
  selectedCustomerId: null,
};

const invoiceFilterReducer = createReducer(
  initialState,
  on(selectCustomer, (state, { customerId }): State => ({ ...state, selectedCustomerId: customerId })),
  on(setFilterFormExpanded, (state, { expanded }): State => ({ ...state, filterFormExpanded: expanded })),
);

export function reducer(state: State | undefined, action: Action) {
  return invoiceFilterReducer(state, action);
}

export const selectInvoiceFilterState = createFeatureSelector< State>(featureKey);
export const selectFilterFormExpanded = createSelector(selectInvoiceFilterState, (state: State) => state.filterFormExpanded);
