import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-more-errors',
    template: `
    <mat-card
      appearance="outlined"
      class="card"
      style="background-color: #323232; color: #b9b9b9; margin-bottom: 10px; font-family: TimesNewRoman"
      *ngFor="let error of data"
    >
      {{ error.payload.data.error.message }}
    </mat-card>
    <button mat-raised-button (click)="closeClicked()" color="primary" style="float: right;">
      Close
    </button>
  `,
    styleUrls: [],
    standalone: true,
    imports: [
        NgFor,
        MatCard,
        MatButton,
    ],
})
export class MoreErrorsComponent {
  constructor(
    public dialogRef: MatDialogRef<MoreErrorsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  closeClicked() {
    this.dialogRef.close();
  }
}
