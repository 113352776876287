import { Injectable, OnDestroy } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { EntityOp, ofEntityOp } from '@ngrx/data';
import { MatSnackBar } from '@angular/material/snack-bar';
import { bufferTime, filter, Subject, takeUntil } from 'rxjs';
import { ErrorSnackbarComponent } from '@app/modules/error-message/components/error-snack-bar.component';

type Method = 'POST' | 'PUT' | 'GET' | 'DELETE';

interface SimpleError {
  status: number;
  message: string;
  method: Method;
  entityName?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorReportingService implements OnDestroy {
  destroyed$ = new Subject<void>();
  constructor(private actions$: Actions, private snackBar: MatSnackBar) {
    // this.actions$.subscribe(action => console.log('the action: ', action));
    this.actions$
      .pipe(
        ofEntityOp(
          EntityOp.QUERY_ALL_ERROR,
          EntityOp.QUERY_BY_KEY_ERROR,
          EntityOp.QUERY_LOAD_ERROR,
          EntityOp.QUERY_MANY_ERROR,
          EntityOp.SAVE_ADD_ONE_ERROR,
          EntityOp.SAVE_ADD_MANY_ERROR,
          EntityOp.SAVE_DELETE_ONE_ERROR,
          EntityOp.SAVE_UPDATE_ONE_ERROR,
          EntityOp.SAVE_UPSERT_ONE_ERROR,
          EntityOp.SAVE_DELETE_MANY_ERROR,
          EntityOp.SAVE_UPDATE_MANY_ERROR,
          EntityOp.SAVE_UPSERT_MANY_ERROR,
        ),
        // filter out errors which we don't want to show
        filter(this.notAnErrorFilter),
        // deliver all errors within timeframe in an array
        bufferTime(1500),
        takeUntil(this.destroyed$),
      )
      .subscribe((errorArray) => {
        // console.log('the errorArray: ', errorArray);
        if (errorArray.length > 0) {
          if (errorArray.length === 1) {
            this.showError(errorArray[0], 20000);
          } else {
            this.showErrors(errorArray, 20000);
          }
        }
      });
  }

  // getFormattedError(error: any): FormattedError {
  //   const status = error?.payload?.data?.error?.error?.status;
  //   const message = error?.payload?.data?.error?.message;
  //   const method = error?.payload?.data?.error?.requestData?.method;
  //   const entityName = error?.payload?.entityName;
  //   return {
  //     status,
  //     message,
  //     method,
  //     entityName,
  //   };
  // }

  getFormattedErrorRecursively(error: any, maxDepth = 10, currentDepth = 0, errorData = {} as SimpleError): SimpleError {
    if (
      typeof error === 'string' ||
      error == null ||
      Object.keys(error)?.length === 0 ||
      (errorData.status != null &&
        errorData.message != null &&
        errorData.method != null &&
        errorData.entityName != null)
    ) {
      return errorData;
    }

    if (currentDepth === maxDepth) return {} as SimpleError;

    const keys = Object.keys(error);
    if (keys.includes('status') && errorData.status == null) errorData.status = error.status;
    if (keys.includes('message') && errorData.message == null) errorData.message = error.message;
    if (keys.includes('method') && errorData.method == null) errorData.method = error.method;
    if (keys.includes('entityName') && errorData.entityName == null)
      errorData.entityName = error.entityName;

    if (error?.error?.message) {
      let message = error.error.message;
      if (error?.error?.details) {
        message += ': ' + error.error.details;
      }

      errorData.message = message;
    }

    if (
      errorData.status == null ||
      errorData.message == null ||
      errorData.method == null ||
      errorData.entityName == null
    ) {
      for (const key of keys) {
        this.getFormattedErrorRecursively(error[key], maxDepth, currentDepth + 1, errorData);
      }
    }
    return errorData;
  }

  displayError(message: string, duration: number) {
    this.snackBar.openFromComponent(ErrorSnackbarComponent, {
      duration,
      data: {
        message: message ?? 'An error occurred',
      },
    });
  }

  displayErrors(
    message: string,
    duration: number,
    numberOfErrors: number,
    otherErrors: SimpleError[],
  ) {
    this.snackBar.openFromComponent(ErrorSnackbarComponent, {
      duration,
      data: {
        message: message ?? 'An error occurred',
        numberOfErrors,
        otherErrors: JSON.stringify(otherErrors),
      },
    });
  }

  showError(errorObj: unknown, duration: number) {
    const error = this.getFormattedErrorRecursively(errorObj);
    if (error?.status == null && error?.message == null && error?.entityName == null && error?.method == null) {
      this.displayError('Error processing failed', duration);
    }
    const codeIsIn400s = error.status >= 400 && error.status < 500;
    const codeIsIn500s = error.status >= 500 && error.status < 600;
    if (codeIsIn500s) {
      this.displayError(
        error?.message != null
          ? error?.message
          : error?.method && error?.entityName
          ? this.createErrorMessage(error?.method, error?.entityName)
          : 'An error occurred',
        duration,
      );
    } else if (codeIsIn400s) {
      this.displayError(error?.message ?? 'An error occurred', duration);
    }
  }

  showErrors(errorArray: unknown[], duration: number) {
    const errors: SimpleError[] = [];
    for (const error of errorArray) {
      errors.push(this.getFormattedErrorRecursively(error));
    }
    const codeIsIn400s = errors[0].status >= 400 && errors[0].status < 500;
    const codeIsIn500s = errors[0].status >= 500 && errors[0].status < 600;
    if (codeIsIn500s) {
      this.displayErrors(
        errors[0]?.message != null
          ? errors[0]?.message
          : errors[0]?.method && errors[0]?.entityName
          ? this.createErrorMessage(errors[0]?.method, errors[0]?.entityName)
          : 'An error occurred',
        duration,
        errors.length - 1,
        errors.slice(1, errors.length),
      );
    } else if (codeIsIn400s) {
      this.displayErrors(
        errors[0].message ?? 'An Error occurred',
        duration,
        errors.length - 1,
        errors.slice(1, errors.length),
      );
    }
  }

  private notAnErrorFilter(err: any): boolean {
    if (
      err?.payload?.data?.error?.message?.slice(0, 37) === 'No employee is associated to the user'
    )
      return false;

    // if ((err?.payload?.data?.error?.message as string).toLowerCase().includes('there are no prices set for billableitem'))
    //   return false;

    return err?.payload?.data?.error?.error?.status !== 404;
  }

  createErrorMessage(method: Method, entityName: string): string {
    const action =
      method === 'POST'
        ? 'creating'
        : method === 'PUT'
        ? 'updating'
        : method === 'GET'
        ? 'getting'
        : method === 'DELETE'
        ? 'deleting'
        : '<action>';

    return `There was an error ${action} the ${entityName ?? 'entity'}`;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
